import React from "react"
import Footer from "../components/Footer"
import Contacts from "../components/Contacts"
import Head from "../components/Head"
import { Link } from "gatsby"

// Images

import BackBuch1_1JPG from "../static/backbuch1_1.jpg"
import BackBuch1_2JPG from "../static/backbuch1_2.jpg"
import BackBuch1_3JPG from "../static/backbuch1_3.jpg"
import BackBuch2_1JPG from "../static/backbuch2_1.jpg"
import BackBuch2_2JPG from "../static/backbuch2_2.jpg"
import BackBuch2_3JPG from "../static/backbuch2_3.jpg"
import KochBuch1 from "../static/kochbuch1.jpg"
import KochBuch2 from "../static/kochbuch2.jpg"
import KochBuch3 from "../static/kochbuch3.jpg"

const openModal = (target, height, width) => {
  if (target.style.width === "100%") {
    target.style.width = width
    target.style.heigth = height
  } else {
    target.style.width = "100%"
    target.style.height = "auto"
  }
}

class Leseprobe extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSrc: null,
    }
  }
  render() {
    return (
      <div>
        <Head />
        <div className="container">
          <div className="main">
            <div className="header">
              <div className="title">
                {" "}
                <a href="/">
                  <h1> </h1>
                </a>{" "}
              </div>
            </div>
            <div className="content">
              <div className="item">
                <h1>Leseprobe Backbuch - Band I</h1>
                <br />
                <p className="MsoNormal">
                  <b>Hanklich</b>
                </p>
                <p className="MsoNormal">
                  <img
                    src={BackBuch1_1JPG}
                    onClick={e => {
                      openModal(e.target, "172px", "180px")
                    }}
                    border="0"
                    height="172"
                    width="180"
                    alt="BackBuch1_1"
                  />
                </p>
                <p className="MsoNormal">
                  <span>
                    <u>Hinweis:</u>
                    Klicken Sie auf die Grafik, um sie zu vergrößern.
                  </span>
                </p>
                <p className="MsoNormal">
                  Was wäre eine siebenbürgische Hochzeit ohne die Hanklich? Das
                  siebenbürgische &#8222;Nationalgebäck&#8220;.
                  <br />
                  Ich erinnere mich heute, als wäre es gestern gewesen, wie die
                  Oma damals den Teig knetete und die vielen Eier zerschlug. Von
                  den Rosinen durfte ich ein bisschen naschen, bevor sie auf der
                  Hanklich verteilt wurden. Diese schob sie dann in den großen
                  heißen Backsteinofen und ich beobachtete, wie es darin
                  knisterte während die Hanklich backte. Dieser unvergleichliche
                  Geruch in Omas Küche. Das erste warme Stück, mit etwas
                  Puderzucker bestreut, hhhhmmm, wie es auf der Zunge zerging!
                  Heute machen wir die Hanklich im modernen elektrischen Ofen,
                  aber heute wie damals, ist das erste warme Stück Hanklich
                  einfach köstlich!
                </p>
                <p className="MsoNormal" align="right">
                  <strong>Edith Gabber</strong>
                </p>
                <p className="MsoNormal">
                  Das vollständige Rezept finden Sie in &#8222;Katharinas
                  Backrezepte&#8220;, dass Sie hier{" "}
                  <Link to="/bestellung/">direkt bestellen</Link> können!
                </p>
                <p className="MsoNormal">
                  <b>Cremeschnitten</b>
                </p>
                <p className="MsoNormal">
                  <img
                    src={BackBuch1_2JPG}
                    onClick={e => {
                      openModal(e.target, "195px", "180px")
                    }}
                    border="0"
                    height="195"
                    width="180"
                    alt="BackBuch1_2"
                  />
                </p>
                <p className="MsoNormal">
                  <span>
                    <u>Hinweis:</u>
                    Klicken Sie auf die Grafik, um sie zu vergrößern.
                  </span>
                </p>
                <p className="MsoNormal">
                  Die überaus schmackhaften Cremeschnitten, die haben schon so
                  manchen kleinen Familienstreit ausgelöst. Die Lösung sah dann
                  so aus, dass für jeden die gleiche Anzahl an wohlschmeckenden
                  Schnitten gebacken wurde und gerecht aufgeteilt wurde. Ein
                  bisschen Mühe macht es zwar die mürben Butterteigblätter zu
                  backen, zusammen mit der Creme bescheren sie jedoch eine
                  unvergleichlich fluffige Gaumenfreude, dass man einfach nicht
                  widerstehen kann!
                </p>
                <p className="MsoNormal">
                  Ich erinnere mich auch nur allzu gerne daran, mit welch
                  verschmitztem Lächeln und leuchtenden Augen einer unserer
                  Cousins einmal deklarierte: &#8222;Heute muss ich zur Oma, die
                  hat wieder die guten Cremeschnitten gebacken!&#8220;
                </p>
                <p className="MsoNormal" align="right">
                  <strong>Udo Gabber</strong>
                </p>
                <p className="MsoNormal">
                  Mit Katharinas Backrezepten können Sie die hausgemachten
                  Creme- schnitten jetzt auch einfach selber machen! Einfach
                  direkt <Link to="/bestellung/">hier bestellen!</Link>
                </p>
                <p className="MsoNormal">
                  <b>Himmlische Torte</b>
                </p>
                <p className="MsoNormal">
                  <img
                    src={BackBuch1_3JPG}
                    onClick={e => {
                      openModal(e.target, "190px", "180px")
                    }}
                    border="0"
                    height="190"
                    width="180"
                    alt="BackBuch1_3"
                  />
                </p>
                <p className="MsoNormal">
                  <span>
                    <u>Hinweis:</u>
                    Klicken Sie auf die Grafik, um sie zu vergrößern.
                  </span>
                </p>
                <p className="MsoNormal">
                  Die Himmlische Torte, die hat ihren Namen, wenn man mich
                  fragt, mehr als verdient! Von Torten mit Quark konnte ich mich
                  früher nie begeistern lassen. Und plötzlich stand sie da, und
                  eher aus Rücksicht und um keinen zu beleidigen, probierte ich
                  ganz vorsichtig die &#8222;Himmlische Torte&#8220; um dann in
                  ein bis heute nicht enden wollendes Geschmackskoma zu fallen.
                  So leicht und weich wie sie mundet, lieblich sonnig und doch
                  vergnüglich, nach leichtem Mandelduft, eine wahrhaftige
                  Delikatesse vor der ich mich verneige!
                </p>
                <p className="MsoNormal" align="right">
                  <strong>Sebastian Roth</strong>
                </p>
                <p className="MsoNormal">
                  &#8222;Katharinas Backrezepte&#8220; macht es möglich und Sie
                  können direkt <Link to="/bestellung/">hier bestellen!</Link>
                  <br />
                </p>
              </div>
              <div className="item">
                <h1>Leseprobe Backbuch - Band II</h1>
                <br />
                <p className="MsoNormal">
                  <b>Grillagetorte</b>
                </p>
                <p className="MsoNormal">
                  <img
                    src={BackBuch2_1JPG}
                    onClick={e => {
                      openModal(e.target, "172px", "180px")
                    }}
                    border="0"
                    height="172"
                    width="180"
                    alt="BackBuch2_1"
                  />
                </p>
                <p className="MsoNormal">
                  <span>
                    <u>Hinweis:</u>
                    Klicken Sie auf die Grafik, um sie zu vergrößern.
                  </span>
                </p>
                <p className="MsoNormal">
                  Auf einer festlichen Kaffeetafel darf eine Grillagetorte nicht
                  fehlen. Dieses exklusive &#8222;Schmankerl&#8220; erfreut den
                  Gaumen aller Tortenesser. Geschmolzener Zucker mit Nüssen, das
                  macht die Besonderheit der so genannten Grillage aus. Früher
                  stellte man die Grillage her, indem man den geschmolzenen und
                  fest gewordenen Zucker in einen stabilen Papierbeutel packte,
                  diesen auf einen Stein legte und mit einem Hammer
                  draufklopfte, um die harte Zuckermasse zu zerkleinern.
                  Heutzutage hilft man mit einem Nudelholz nach, wenn nötig. Ist
                  die Torte fertig, heißt es nur noch: Augen zu, Mund auf und im
                  Tortenhimmel schwelgen!
                </p>
                <p className="MsoNormal" align="right">
                  <strong>Iris Gabber</strong>
                </p>
                <p className="MsoNormal">
                  &#8222;Katharinas Backrezepte - Band II&#8220; macht es
                  möglich und Sie können direkt{" "}
                  <Link to="/bestellung/">hier bestellen.</Link>
                </p>
                <p className="MsoNormal">
                  <b>Honigkuchen (Bichel)</b>
                </p>
                <p className="MsoNormal">
                  <img
                    src={BackBuch2_2JPG}
                    onClick={e => {
                      openModal(e.target, "172px", "180px")
                    }}
                    border="0"
                    height="172"
                    width="180"
                    alt=""
                  />
                </p>
                <p className="MsoNormal">
                  <span>
                    <u>Hinweis:</u>
                    Klicken Sie auf die Grafik, um sie zu vergrößern.
                  </span>
                </p>
                <p className="MsoNormal">
                  In Siebenbürgen gab es in unserem Dorf die so genannten
                  &#8222;Bichel&#8220; (aus Honigkuchen ausgestochene Formen)
                  traditionell, in Form eines Osterhasen für die Kinder, zum
                  Osterfest. Die Zeremonie am Osterfest ist mir in wacher
                  Erinnerung geblieben. Nach der Kirche versammelte man sich auf
                  dem Pfarrhof und dort gab es dann eine so genannte
                  &#8222;Bescherung&#8220; bei der alle Kinder dem Alter nach
                  vorgehen und sich den köstlichen Osterhasen aus Honigkuchen
                  abholen durften. So tat ich dem auch voller Begeisterung. Als
                  meine Großmutter ein Kind war, kaufte man die Bichel fertig
                  aus der Stadtbäckerei und diese waren eine Art Brezel. Während
                  der Kriegszeit gab es jedoch keine zu erwerben und so fing man
                  an aus dem Honigkuchen, der traditionell an Weihnachten
                  verwendet wurde, Osterhasen für die Kinder zu backen. Nach dem
                  Krieg behielt man den Namen &#8222;Bichel&#8220; bei und den
                  wohlschmeckenden Honigkuchen auch.
                </p>
                <p className="MsoNormal" align="right">
                  <strong>Udo Gabber</strong>
                </p>
                <p className="MsoNormal">
                  &#8222;Katharinas Backrezepte - Band II&#8220; macht es
                  möglich und Sie können direkt{" "}
                  <Link to="/bestellung/">hier bestellen.</Link>
                </p>
                <p className="MsoNormal">
                  <b>Zuckerhütchen</b>
                </p>
                <p className="MsoNormal">
                  <img
                    src={BackBuch2_3JPG}
                    onClick={e => {
                      openModal(e.target, "172px", "180px")
                    }}
                    border="0"
                    height="172"
                    width="180"
                    alt=""
                  />
                </p>
                <p className="MsoNormal">
                  <span>
                    <u>Hinweis:</u>
                    Klicken Sie auf die Grafik, um sie zu vergrößern.
                  </span>
                </p>
                <p className="MsoNormal">
                  Als ich Oma von den köstlichen Zuckerhütchen erzählte, die
                  Mutter gebacken hat, schoss es geradezu aus ihrem Munde:
                  &#8222;Oben spitz und unten breit, durch und durch voll
                  Süßigkeit&#8220;, und dem stimme ich vollkommen zu. Dies
                  trifft nicht nur auf die als Zuckerhüte bekannten Kegel aus
                  Zucker zu, sondern auch auf die Backspezialität
                  &#8222;Zuckerhütchen&#8220;. Die leckeren Hütchen haben uns
                  alle in ihren Bann gezogen. Zuweilen herrschte an Feiertagen
                  eine regelrechte Zuckerhütchenmanie. Freunde und Bekannte
                  fragen zu gewissen Jahreszeiten (Weihnachten, Ostern oder an
                  Geburtstagen) pünktlich nach den Zuckerhütchen und wir bringen
                  gerne reichlich davon mit.
                </p>
                <p className="MsoNormal" align="right">
                  <strong>Heidrun Roth</strong>
                </p>
                <p className="MsoNormal">
                  &#8222;Katharinas Backrezepte - Band II&#8220; macht es
                  möglich und Sie können direkt{" "}
                  <Link to="/bestellung/">hier bestellen!</Link>
                </p>
              </div>
              <div className="item">
                <h1>Leseprobe Kochbuch</h1>
                <br />
                <p className="MsoNormal">
                  <b>Siebenbürgisches Bauernbrot</b>
                </p>
                <p className="MsoNormal">
                  <img
                    src={KochBuch1}
                    onClick={e => {
                      openModal(e.target, "182px", "180px")
                    }}
                    border="0"
                    height="182"
                    width="180"
                    alt=""
                  />
                </p>
                <p className="MsoNormal">
                  <span>
                    <u>Hinweis:</u>
                    Klicken Sie auf die Grafik, um sie zu vergrößern.
                  </span>
                </p>
                <p className="MsoNormal">
                  Beim siebenbürgischen Bauernbrot denke ich immer sofort an die
                  große Mulde, in der wir früher in Siebenbürgen den Teig
                  kneteten. Meistens erworben wir diese von den Zigeunern,
                  welche sie aus einem Holzstamm herausschnitzten. Heute
                  bedienen wir uns ganz einfach einer Knetmaschine! Ohne viel
                  Arbeit entsteht der Sauerteig. Das Brot wird geformt und kommt
                  in den Ofen. Nach dem Backen hat sich eine dicke knusprige
                  Kruste gebildet, die es nun abzuklopfen gilt. Da sind dann
                  alle Familienmitglieder so oft es nur geht dabei, denn diese
                  knusprigen abgeklopften Krustenteile schmecken fantastisch!
                  Und dann das Brot, es gibt wohl nichts Köstlicheres als ein
                  frischgebackenes siebenbürgisches Bauernbrot. Mit Sakuska
                  bestrichen, das höchste der Gefühle!
                </p>
                <p className="MsoNormal" align="right">
                  <strong>Heidrun Roth</strong>
                </p>
                <p className="MsoNormal">
                  Beide Rezepte finden Sie in &#8222;Katharinas
                  Kochrezepte&#8220;, direkt{" "}
                  <Link to="/bestellung/">hier zu bestellen!</Link>
                </p>
                <p className="MsoNormal">
                  <b>Zwiebelschnitzel</b>
                </p>
                <p className="MsoNormal">
                  <img
                    src={KochBuch2}
                    onClick={e => {
                      openModal(e.target, "170px", "180px")
                    }}
                    border="0"
                    height="170"
                    width="180"
                    alt=""
                  />
                </p>
                <p className="MsoNormal">
                  <span>
                    <u>Hinweis:</u>
                    Klicken Sie auf die Grafik, um sie zu vergrößern.
                  </span>
                </p>
                <p className="MsoNormal">
                  Wenn die Mama mich fragt, was sie kochen soll wenn ich nach
                  Hause komme, dann kann sie von mir nur eine Antwort erwarten:
                  Zwiebelschnitzel mit Püree, mein Lieblingsleibgericht. Der
                  Schnitzel zergeht einem förmlich auf der Zunge und zusammen
                  mit dem Püree bildet das eine perfekte Geschmacksnote.
                  Zusätzlich dazu bin ich der Meinung, dass keine Soße so sämig
                  und geschmackvoll ist wie diese aus Zwiebeln und Sud
                  zubereitete Feinkost. Wenn ich nur daran denke, dann freue ich
                  mich jetzt schon auf den nächsten Besuch zu Hause!
                  Zwischenzeitlich versuche ich mich auch selbst mit den
                  Zwiebelschnitzeln nach den Anweisungen in &#8222;Katharinas
                  Kochrezepte&#8220; und freue mich dass Mutter alle unsere
                  Lieblingsrezepte zu Papier gebracht hat.
                </p>
                <p className="MsoNormal" align="right">
                  <strong>Edith Gabber</strong>
                </p>
                <p className="MsoNormal">
                  Bestellen können Sie das Kochbuch direkt{" "}
                  <Link to="/bestellung/">hier, online!</Link>
                </p>
                <p className="MsoNormal">
                  <b>Gefülltes Sauerkraut</b>
                </p>
                <p className="MsoNormal">
                  <img
                    src={KochBuch3}
                    onClick={e => {
                      openModal(e.target, "223px", "180px")
                    }}
                    border="0"
                    height="223"
                    width="180"
                    alt=""
                  />
                </p>
                <p className="MsoNormal">
                  <span>
                    <u>Hinweis:</u>
                    Klicken Sie auf die Grafik, um sie zu vergrößern.
                  </span>
                </p>
                <p className="MsoNormal">
                  Dieses köstliche Gericht gab es früher in unserer Familie
                  traditionell nur an Weihnachten, an Neujahr und bei Hochzeiten
                  und das nicht ohne Grund! Bekanntlich wird an den Feiertagen
                  nur das allerbeste Mahl verzehrt. Oft wurde es im
                  Steinbackofen nach dem Brotbacken weich gegart. Heute gehört
                  das gefüllte Sauerkraut glücklicherweise zu den Speisen, die
                  wir öfter zubereiten, denn es schmeckt einfach herrlich! Ob
                  mit Süßkraut oder selbst eingelegtem Sauerkraut erhält es eine
                  besonders gute Geschmacksnote wenn man gut pfeffert, etwas
                  gehackten Speck hinzu gibt sowie geräucherten Schweinebauch.
                  In den Ofen schieben, im Nu ist es fertig und dann heißt es
                  nur noch Schlemmen bis es nicht mehr geht! Einfach lecker!
                </p>
                <p className="MsoNormal" align="right">
                  <strong>Iris Gabber</strong>
                </p>
                <p className="MsoNormal">
                  Auch dieses Originalrezept finden Sie in &#8222;Katharinas
                  Kochrezepte&#8220;, dass Sie{" "}
                  <Link to="/bestellung/">hier bestellen</Link> können!
                </p>
              </div>
              <div className="item">
                <h1>Bestellung</h1>
                <br />
                <p className="MsoNormal">
                  Sie haben Interesse an Katharinas Backrezepte (Band I und Band
                  II), an Katharinas Kochrezepte oder Einweckrezepten? Bei uns
                  können Sie die vier Bücher einzeln oder zusammen{" "}
                  <Link to="/bestellung/">online bestellen.</Link>
                  <br />
                </p>
              </div>
            </div>
            <Contacts />
            <div className="clearer">
              <span />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}
export default Leseprobe
